<template>
  <div class="elite-tabs-wrapper-content">
    <div class="custom-header">
      <h3>{{ `${organizationGroups.name}` }} - {{ $t("COMMON.LOGS") }}</h3>
    </div>
    <div class="col-12 mt-4">
      <h3>{{ $t("COMMON.ORGANIZATIONS") }}</h3>
      <el-table :data="organizationGroups.organizations" style="width: 100%">
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="name" label="Name"></el-table-column>
        <el-table-column prop="email" label="Email"></el-table-column>
        <el-table-column prop="address" label="Address"></el-table-column>
        <el-table-column prop="phone" label="Phone"></el-table-column>
        <el-table-column prop="created_at" label="Created At">
          <template slot-scope="scope">
            {{ new Date(scope.row.created_at).toLocaleString() }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--     <log-list-table :filterSubject="organizationGroups" /> -->
  </div>
</template>
<script>
/* import LogListTable from "@/views/Pages/DefaultModule/LogManagement/partials/LogListTable.vue"; */
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
export default {
  name: "organization-groups-view-organizations",

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  props: ["organizationGroups"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    user(user) {},
    organizationGroups: {
      handler(newValue) {
        // Handle any watch logic if needed
      },
      deep: true, // Watch nested properties of organizationGroups
    },
  },
};
</script>
