<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!organizationGroups">
      <span class="loader"></span>
    </span>
    <div v-if="organizationGroups" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <!-- Tabs and tab-panes here -->
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <organization-groups-view-global
            :organizationGroups="organizationGroups"
          />
        </tab-pane>

        <tab-pane title="organisation" id="2" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.THE_ORGANIZATIONS") }}
          </span>
          <organization-groups-view-organizations
            :organizationGroups="organizationGroups"
          />
        </tab-pane>
        <!-- Other tab-panes -->
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Table,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { Tabs, TabPane } from "@/components";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import PackageSelector from "@/components/PackageSelector.vue";
import ValidationError from "@/components/ValidationError.vue";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import OrganizationGroupsViewGlobal from "../partials/OrganizationGroupsViewGlobal.vue";
import OrganizationGroupsViewUsers from "../partials/OrganizationGroupsViewUsers.vue";
import OrganizationGroupsViewRoles from "../partials/OrganizationGroupsViewRoles.vue";
/* import OrganizationGroupsViewLogs from "../partials/OrganizationGroupsViewLogs.vue"; */
import OrganizationGroupsViewSubscriptions from "../partials/OrganizationGroupsViewSubscriptions.vue";
import OrganizationGroupsViewAuthorizations from "../partials/OrganizationGroupsViewAuthorizations.vue";
import OrganizationGroupsViewOrganizations from "../partials/OrganizationGroupsViewOrganizations.vue";
export default {
  layout: "DashboardLayout",
  components: {
    Tooltip,
    Table,
    TableColumn,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Select,
    Option,
    Button,
    Tabs,
    TabPane,
    OrganizationGroupsViewGlobal,
    OrganizationGroupsViewUsers,
    OrganizationGroupsViewRoles,
    /*     OrganizationGroupsViewLogs, */
    OrganizationGroupsViewSubscriptions,
    OrganizationGroupsViewAuthorizations,
    OrganizationGroupsViewOrganizations,
    ListFileComponent,
    PackageSelector,
    ValidationError,
  },
  mixins: [formMixin, requestErrorMixin],
  props: {
    organizationGroupsId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      organizationGroups: null,
      loading: false,
      showSubscriptionAddModal: false,
      addSubscriptionPackageId: null,
    };
  },
  watch: {
    organizationGroups(newVal) {
      console.log(newVal, "organizationGroups in watcher");
    },
  },
  created() {
    this.get();
  },
  methods: {
    async get() {
      try {
        console.log("Dispatching get action");
        await this.$store.dispatch(
          "organizationGroups/getWithOrg",
          this.organizationGroupsId
        );
        this.organizationGroups =
          this.$store.getters["organizationGroups/organizationGroup"];
        console.log(
          this.organizationGroups,
          "this.organizationGroups in VIEW by ID"
        );
      } catch (error) {
        this.$notify({
          type: "danger",
          message: error,
          /*   this.$t("ERRORS.SOMETHING_WENT_WRONG") */
        });
      }
    },
    /*   async generateSubscriptionInvoice() {
      this.loading = true;
      try {
        const invoice = await this.$store.dispatch(
          "organizationGroups/generateSubscriptionInvoice",
          {
            organizationGroupsId: this.organizationGroups.id,
            data: { package: this.addSubscriptionPackageId },
          }
        );

        this.$notify({
          type: "success",
          message: this.$t("ORGANIZATIONS.SUBSCRIPTION_INVOICE_ADDED"),
        });
        this.loading = false;

        this.$router.push(this.$objectViewRoute(invoice));
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
        this.setApiValidation(error.response.data.errors);
      }
    }, */
    getFilesObject() {
      return {
        ...cloneDeep(this.organizationGroups),
        organizationGroups: cloneDeep(this.organizationGroups),
      };
    },
  },
};
</script>
