import { BILLING_ENTITY_TYPE_COMPANY } from "@/constants/billingInformations";
import { PHONE_TYPE_MOBILE } from "@/constants/common";
import organizations from "../../../../i18n/fr/organizations";

export default {
  type: "organization-group",
  name: null,
  description: "",
  address: null,
  owner: {
    type: "users",
    id: null,
  },
  reseller: {
    type: "resellers",
    id: null,
  },
  organizations: [],
};
