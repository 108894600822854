<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("ORGANIZATIONS.ORGANIZATIONS_GROUPS_LIST") }}
            </h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openOrganizationGroupsCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_ORGANIZATIONS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("ORGANIZATIONS.ADD_ORGANIZATION_GROUP")
              }}</span>
            </base-button>
            <!--   <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_ORGANIZATIONS)"
              :objectType="'organizationGroups'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            /> -->
          </div>
        </div>
      </div>

      <organization-groups-list-table
        @onViewOrganizationsGroups="openOrganizationGroupsViewModal"
        @onEditOrganizationGroup="openOrganizationGroupsEditModal"
        @onDeleteOrganizationGroup="deleteOrganizationGroups"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewOrganizationGroupsModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewOrganizationGroupsModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'ORGANIZATION_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("ORGANIZATIONS.VIEW_ORGANIZATION") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openOrganizationGroups"
                :objectType="'organizationGroups'"
                :objectId="openOrganizationGroups.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="edit"
                  @click="
                    openOrganizationGroupsEditModal(openOrganizationGroups)
                  "
                >
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button
                  class="delete"
                  @click="deleteOrganizationGroups(openOrganizationGroups)"
                >
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeOrganizationGroupsModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <!-- //view org comp  -->
          <div class="resizable-wrapper-content-body">
            <view-organization-groups-component
              v-if="openOrganizationGroups"
              :organizationGroupsId="openOrganizationGroups.id"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditOrganizationGroupsModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditOrganizationGroupsModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'ORGANIZATION'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("ORGANIZATIONS.EDIT_ORGANIZATION_GROUP") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="
                    openOrganizationGroupsViewModal(openOrganizationGroups)
                  "
                >
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeOrganizationGroupsModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-organization-groups-component
              v-if="openOrganizationGroups"
              :organizationGroupsId="openOrganizationGroups.id"
              @onViewOrganizationsGroups="openOrganizationGroupsViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddOrganizationGroupsModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddOrganizationGroupsModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'ORGANIZATION'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("ORGANIZATIONS.ADD_ORGANIZATION_GROUP") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeOrganizationGroupsModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-organization-groups-component
              @onViewOrganizationsGroups="openOrganizationGroupsViewModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import OrganizationGroupsListTable from "./partials/OrganizationGroupsListTable.vue";
import EditOrganizationGroupsComponent from "./components/EditOrganizationGroupsComponent.vue";
import AddOrganizationGroupsComponent from "./components/AddOrganizationGroupsComponent.vue";
import ViewOrganizationGroupsComponent from "./components/ViewOrganizationGroupsComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    OrganizationGroupsListTable,
    NotificationSubscription,
    EditOrganizationGroupsComponent,
    AddOrganizationGroupsComponent,
    ViewOrganizationGroupsComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},
  watch: {},

  data() {
    const organizationGroupsId = this.$route.query.id;
    console.log(organizationGroupsId, "organizationGroupsId in list org ");
    const action = this.$route.query.action;
    let isViewOrganizationGroupsModalOpened = false;
    let isEditOrganizationGroupsModalOpened = false;
    let isAddOrganizationGroupsModalOpened = false;
    let openOrganizationGroups = null;
    if (organizationGroupsId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewOrganizationGroupsModalOpened = false;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditOrganizationGroupsModalOpened = true;
      }
      openOrganizationGroups = { id: organizationGroupsId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddOrganizationGroupsModalOpened = true;
    }
    console.log(
      openOrganizationGroups,
      "test openOrganizationGroups in LIST EDIT "
    );
    return {
      isViewOrganizationGroupsModalOpened: isViewOrganizationGroupsModalOpened,
      isEditOrganizationGroupsModalOpened: isEditOrganizationGroupsModalOpened,
      isAddOrganizationGroupsModalOpened: isAddOrganizationGroupsModalOpened,
      openOrganizationGroups: openOrganizationGroups,
      renderKey: 1,
    };
  },

  methods: {
    openOrganizationGroupsCreateModal() {
      this.closeOrganizationGroupsModal();
      this.isAddOrganizationGroupsModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Organizations Groups",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openOrganizationGroupsViewModal(organizationGroup, reRender = false) {
      this.closeOrganizationGroupsModal();
      console.log(
        this.openOrganizationGroups,
        "  this.openOrganizationGroups openOrganizationGroupsViewModal"
      );
      this.openOrganizationGroups = organizationGroup;
      this.isViewOrganizationGroupsModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Organizations Groups",
          query: {
            id: this.openOrganizationGroups.id,
            action: QUERY_ACTIONS_VIEW,
          },
        }).href
      );
    },
    openOrganizationGroupsEditModal(organizationgroup) {
      this.closeOrganizationGroupsModal();
      this.openOrganizationGroups = organizationgroup;
      this.isEditOrganizationGroupsModalOpened = true;
      console.log(
        this.openOrganizationGroups,
        " this.openOrganizationGroups LIST"
      );
      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Organizations Groups",
          query: {
            id: this.openOrganizationGroups.id,
            action: QUERY_ACTIONS_EDIT,
          },
        }).href
      );
    },
    closeOrganizationGroupsModal() {
      this.isAddOrganizationGroupsModalOpened = false;
      this.isViewOrganizationGroupsModalOpened = false;
      this.isEditOrganizationGroupsModalOpened = false;
      this.openOrganizationGroups = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Organizations Groups",
          query: {},
        }).href
      );
    },

    async deleteOrganizationGroups(organization) {
      const confirmation = await swal.fire({
        text: this.$t("ORGANIZATIONS.DELETE_THIS_ORGANIZATION"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText:
          this.$t("COMMON.YES") + ", " + this.$t("COMMON.DELETE"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "organizationGroups/destroy",
            organization.id
          );
          this.renderKey++;
          this.closeOrganizationGroupsModal();
          this.$notify({
            type: "success",
            message: this.$t("ORGANIZATIONS.ORGANIZATION_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
