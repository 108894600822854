<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">
      {{ organizationGroups.name }} - {{ $t("COMMON.USERS") }}
    </h3>
    <user-list-table :filterOrganizationGroup="organizationGroups.id" />
  </div>
</template>

<script>
import UserListTable from "../../UserManagement/partials/UserListTable.vue";
export default {
  name: "organization-group-view-users",

  components: { UserListTable },

  props: ["organizationGroups"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    organizationGroups(organizationGroups) {},
  },
};
</script>
